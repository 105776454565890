import { NgModule } from '@angular/core';
import {appConfigFactory, AppConfigService, CONFIG_TOKEN} from "./config.service";

@NgModule({
  providers: [
    AppConfigService,
    {
      provide: CONFIG_TOKEN,
      useFactory: appConfigFactory,
      deps: [AppConfigService],
    }
  ],
})
export class ConfigModule {}
