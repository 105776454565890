import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightsService } from './services/app-insights.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ConfigModule } from "../config/config.module";
import { MicrosoftClarityService } from './services/microsoft-clarity.service';
import { AnalyticsService } from './services/analytics.service';

@NgModule({
  providers: [
    AppInsightsService,
    GoogleAnalyticsService,
    MicrosoftClarityService,
    AnalyticsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
  imports: [ConfigModule]
})
export class AnalyticsModule {
  constructor(
    private analytics: AnalyticsService,
    private googleAnalytics: GoogleAnalyticsService,
    private clarity: MicrosoftClarityService,
    private appInsights: AppInsightsService
  ) {
    // All services will initialize themselves when injected
    console.log('Analytics module initialized');
  }
} 