export const environment = {
  production: true,
  apiBaseUrl: "https://api.dev.ogarni.ai",
  insights: {
    instrumentationKey: "32c867e1-76ba-433e-8cac-a598b6f1201c"
  },
  entra: {
    clientId: "01541eb8-40ba-4321-b801-3979b6205943",
    authorityUrl: "https://ogarniai.ciamlogin.com/auth.ogarni.ai",
    scopes: [
      "api://a9bcc4f3-f772-4ac4-85e3-577e43e3f8d1/access_as_user"
    ]
  },
  serviceWorker: true,
  googleAnalyticsId: "G-R9W3R7X56M",
  clarityTrackingId: "q61z0uwazl"
};
