import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

// Extended interface for ServiceWorkerRegistration with sync property
interface ExtendedServiceWorkerRegistration extends ServiceWorkerRegistration {
  sync?: {
    register(tag: string): Promise<void>;
  };
  periodicSync?: {
    register(tag: string, options: { minInterval: number }): Promise<void>;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(private swUpdate: SwUpdate) {
    this.checkForUpdates();
    this.debugServiceWorker();
    // The PWA Builder service worker is now registered via index.html
  }

  private isLocalhost(): boolean {
    return window.location.hostname === 'localhost' || 
           window.location.hostname === '127.0.0.1';
  }

  private checkForUpdates(): void {
    if (this.isLocalhost()) {
      console.log('Running on localhost - skipping PWA updates');
      return;
    }

    if (this.swUpdate.isEnabled) {
      // Check for service worker updates
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(() => {
          console.log('Dostępna nowa wersja. Odświeżanie...');
          window.location.reload();
        });

      // Log service worker status
      console.log('Service Worker jest włączony');
      
      // Check if service worker is activated
      navigator.serviceWorker.ready.then(() => {
        console.log('Service Worker jest aktywny');
        this.inspectCaches();
      });

      // Periodically check for updates
      setInterval(() => {
        this.swUpdate.checkForUpdate().then(() => {
          console.log('Sprawdzanie aktualizacji');
        });
      }, 60000); // Check every minute
    } else {
      console.warn('Service Worker nie jest włączony');
    }
  }

  private async debugServiceWorker() {
    if (!navigator.serviceWorker) {
      console.warn('Service Worker nie jest obsługiwany');
      return;
    }
    
    // Add fetch event listener to debug caching
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'CACHE_UPDATED') {
        console.log('Pamięć podręczna zaktualizowana:', event.data.url);
      } else if (event.data && event.data.type === 'DATA_UPDATED') {
        console.log('Dane zaktualizowane o:', event.data.timestamp);
        // You could trigger UI refresh here
      }
    });

    // Monitor cache storage
    this.inspectCaches();
  }

  private async inspectCaches() {
    if ('caches' in window) {
      try {
        const cacheNames = await caches.keys();
        
        for (const cacheName of cacheNames) {
          const cache = await caches.open(cacheName);
          const keys = await cache.keys();
        }
      } catch (error) {
        console.error('Błąd podczas sprawdzania pamięci podręcznej:', error);
      }
    }
  }

  // Method to request notification permission for use when needed
  public async requestNotificationPermission() {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      console.log('Uprawnienie do powiadomień:', permission);
      return permission;
    }
    return 'denied';
  }

  // Helper method to check notification permission status
  public getNotificationPermissionStatus(): string {
    return ('Notification' in window) ? Notification.permission : 'unsupported';
  }
} 