import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfig, CONFIG_TOKEN } from "../../config/config.service";

@Injectable()
export class AppInsightsService {
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            appId: 'ogarniai-app',
            enableAutoRouteTracking: true,
            instrumentationKey: this.appConfig?.insights?.instrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
        },
    });

    constructor(private router: Router, @Inject(CONFIG_TOKEN) private appConfig: AppConfig) {
        if (this.appConfig?.insights?.instrumentationKey) {
            this.appInsights.loadAppInsights();
            console.info("Initialized app insights");
        }
    }

    trackEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackEvent({ name, properties });
    }

    trackTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace({ message, properties });
    }

    trackPageView(name?: string, uri?: string): void {
        this.appInsights.trackPageView({ name, uri });
    }
} 