import {ApplicationConfig, Injectable, InjectionToken} from '@angular/core';

import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {environment} from '../../../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
  ],
};

export const CONFIG_TOKEN = new InjectionToken('CONFIG');

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  getAppConfig(): AppConfig {
    return {
      appHost: window.location.protocol + "//" + window.location.host,
      ...environment
    };
  }
}

export interface AppConfig {
  production: boolean;
  apiBaseUrl: string;
  appHost: string;
  insights: {
    instrumentationKey: string;
  };
  entra: {
    clientId: string;
    authorityUrl: string;
    scopes: string[];
  }
  serviceWorker: boolean;
  googleAnalyticsId: string;
  clarityTrackingId: string;
}

export const appConfigFactory = (appConfigService: AppConfigService) =>
  appConfigService.getAppConfig();
