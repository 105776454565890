import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

export const routes: Routes = [
    {
      path: '',
      loadChildren: () => import('./modules/layout/layout.module').then((m) => m.LayoutModule),
    },
    {
      path: 'auth-callback',
      redirectTo: '',
      pathMatch: 'full'
    },
    {
      path: 'errors',
      loadChildren:
        () => import('./modules/error/error.module').then((m) => m.ErrorModule),
    }
    ,
    {
      path: '**', redirectTo:
        'errors/404'
    }
    ,
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
