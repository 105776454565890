import { Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { MicrosoftClarityService } from './microsoft-clarity.service';

@Injectable()
export class AnalyticsService {
    constructor(
        private appInsights: AppInsightsService,
        private googleAnalytics: GoogleAnalyticsService,
        private clarity: MicrosoftClarityService
    ) {}

    /**
     * Track a custom event across all analytics platforms
     * @param eventName The name of the event
     * @param properties Additional properties for the event
     */
    trackEvent(eventName: string, properties: { [key: string]: any } = {}): void {
        // Track in App Insights
        this.appInsights.trackEvent(eventName, properties);
        
        // Track in Google Analytics
        this.googleAnalytics.trackEvent(eventName, properties);
    }

    /**
     * Track a page view across all analytics platforms
     * @param path The path of the page
     * @param title Optional title of the page
     */
    trackPageView(path: string, title?: string): void {
        // Track in App Insights
        this.appInsights.trackPageView(title, path);
        
        // Track in Google Analytics
        this.googleAnalytics.trackPageView(path);
    }

    /**
     * Track a trace/debug message (only in App Insights)
     * @param message The trace message
     * @param properties Additional properties for the trace
     */
    trackTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace(message, properties);
    }
} 