import { Inject, Injectable } from '@angular/core';
import { AppConfig, CONFIG_TOKEN } from "../../config/config.service";
import Clarity from '@microsoft/clarity';

@Injectable()
export class MicrosoftClarityService {
    constructor(@Inject(CONFIG_TOKEN) private appConfig: AppConfig) {
        this.initialize();
    }

    private initialize(): void {
        if (this.appConfig.clarityTrackingId) {
            try {
                Clarity.init(this.appConfig.clarityTrackingId);
                console.info('Microsoft Clarity initialized');
            } catch (e) {
                console.error('Error initializing Microsoft Clarity:', e);
            }
        } else {
            console.error('Microsoft Clarity not initialized - no tracking ID provided');
        }
    }
} 