import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConfig, CONFIG_TOKEN } from "../../config/config.service";
import Analytics from 'analytics';
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';

@Injectable()
export class GoogleAnalyticsService {
    private analytics: any;

    constructor(
        private router: Router,
        @Inject(CONFIG_TOKEN) private appConfig: AppConfig
    ) {
        this.initialize();
        this.setupRouteTracking();
    }

    private initialize(): void {
        if (this.appConfig.googleAnalyticsId) {
            this.analytics = Analytics({
                app: 'ogarniai-app',
                plugins: [
                    googleAnalytics({
                        measurementIds: [this.appConfig.googleAnalyticsId]
                    })
                ]
            });
            console.info('Google Analytics initialized');
        } else {
            console.error('Google Analytics not initialized - no measurement ID provided');
        }
    }

    private setupRouteTracking(): void {
        if (!this.analytics) return;

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.trackPageView(event.urlAfterRedirects);
        });
    }

    trackPageView(path: string): void {
        if (!this.analytics) return;
        
        this.analytics.page({
            path: path
        });
    }

    trackEvent(eventName: string, eventParams: { [key: string]: any } = {}): void {
        if (!this.analytics) return;

        this.analytics.track(eventName, eventParams);
    }
} 